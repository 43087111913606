<template>
  <div class="warning-report-container">
    <RuleSearch @handleAutoSearch="handleAutoSearch" />
    <RuleTable ref="table" v-on="$listeners" />
  </div>
</template>

<script>
import RuleSearch from './RuleSearch'
import RuleTable from './RuleTable'
export default {
  name: 'WarningRule',
  components: { RuleSearch, RuleTable },
  methods: {
    handleAutoSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.dealquery(query)
      })
    }
  }
}
</script>

<style lang="less">
.warning-report-container {
  height: 100%;
}
</style>
